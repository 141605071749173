import styled from "../../styles/styled-components";
import { mediaQueries } from "../../styles/media-queries";
import { H2, H1 } from "../../styles/global";

const RankingPrizesStyles = styled.div`
	.image-prizes-widget {
		width: 100%;
		max-width: 300px;
	}

	.ant-col {
		text-align: center;
	}

	.prize-row {
		padding: 20px 0 20px 0;
	}

	ul, p {
    text-align:left;
	}

	ul {
		display: inline-block;
	}
`;

const PrizesStyles = styled.div`
margin-top: 20px;
	h1 {
		background: ${props => props.theme.primaryColor};
		padding: 5px 5px 5px 10px;
		color: #fff;
		font-size: 1.3em;
	}
`;

export {
	RankingPrizesStyles,
	PrizesStyles
};