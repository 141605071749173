import React, { Component } from "react";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Tabs } from 'antd';

import * as userActions from '../../actions/user'
import * as playersActions from '../../actions/players'
import { StoreState, ApplicationState, PlayersState, ClubsState, Player, MatchesState, UserState } from './../../types';
import PlayerAvatar from '../../components/Player/Player';
import { Link } from './../../components/UI/Link/Link';
import Popover from '../../components/UI/Popover/Popover';
import { BasicTableStyle } from './../../components/PlayerList/PlayerListStyle';
import { SelectedPlayer, SelectedPlayerInfo } from './StatsStyles';
import { Icon, Tooltip } from "antd";
import { redCardSvg, yellowCardSvg } from '../../styles/custom-icons';


const RedCardIcon = (props: any) => <Icon component={redCardSvg} {...props} />;
const YellowCardIcon = (props: any) => <Icon component={yellowCardSvg} {...props} />;
const { TabPane } = Tabs;

import { Element, scroller } from 'react-scroll'

import {
	Title,
	TitleType,
	PlayerStatsList,
	PlayerType,
	Block,
	Button,
	Row,
	Col
} from './../../components';
import { Redirect } from "react-router-dom";
import { StatsStyle } from "../../components/Stats/StatsStyle";

export interface Props {
	application: ApplicationState;
	players: PlayersState;
	clubs: ClubsState;
	matches: MatchesState;
	t: any;
	user: UserState;
}

export interface State {
	selectedPlayer: any,
	selectedPlayerStats: any,
	selectedPlayerAggregatedStats: any,
	navigateToMatch: any
}

const popoverStyle = {
	cursor: 'pointer',
}


class Stats extends Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			selectedPlayer: null,
			selectedPlayerStats: [],
			selectedPlayerAggregatedStats: [],
			navigateToMatch: null
		};
	};

	getPlayerDetails(firstPlayer: Player) {
		playersActions.getPlayerDetails(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId,
			firstPlayer.id
		)
			.then((result: any) => {
				this.setState({
					selectedPlayer: firstPlayer,
					selectedPlayerStats: result.stats || [],
					selectedPlayerAggregatedStats: result.aggregatedWeekStats || []
				});
			});
	}

	setPlayer = (player: any) => {
		playersActions.getPlayerDetails(
			this.props.application.competition.competitionFeed,
			this.props.application.competition.seasonId,
			player.playerId
		)
			.then((result: any) => {
				this.setState({
					selectedPlayer: { ...player, id: player.playerId },
					selectedPlayerStats: result.stats || [],
					selectedPlayerAggregatedStats: result.aggregatedWeekStats || []
				});

				scroller.scrollTo('player-stats', {
					duration: 1000,
					delay: 100,
					smooth: true,
					offset: 0
				})
			});
	};

	render() {
		let selectedPlayerClub = null;
		const { t, matches } = this.props;
		const { selectedPlayerAggregatedStats, navigateToMatch } = this.state;

		const sportSpecificProps: {
			soccerJersey?: string,
			clubBadge?: string,
			portraitFace?: string,
			portraitFaceFallBack?: string
		} = {};

		const selectedPlayerAggregatedStatsTotalPoints = selectedPlayerAggregatedStats
			.reduce((acc: number, item: any) => acc + item.total, 0);

		const selectedPlayer = this.state.selectedPlayer ? { ...this.state.selectedPlayer, ...this.state.selectedPlayer.generalInfo } : null;

		if (selectedPlayer && this.props.clubs.data) {
			selectedPlayerClub = this.props.clubs.data.find((club: any) => club.id === selectedPlayer.clubId);
			sportSpecificProps.soccerJersey = `${this.props.application.competition.assetsCdn}/jerseys/club_${selectedPlayer.clubId}.png`;
			sportSpecificProps.clubBadge = `${this.props.application.competition.assetsCdn}/badges/club_${selectedPlayer.clubId}.png`;
			sportSpecificProps.portraitFace = selectedPlayer.portraitUrl;
			sportSpecificProps.portraitFaceFallBack = `${this.props.application.competition.assetsCdn}/players/dummy.png`;
		}

		const columns = [{
			key: 'weekId',
			title: <Popover content={'Speeldag'} trigger="click">
				<span style={popoverStyle} >
					{'SP'}
				</span>
			</Popover>,
			dataIndex: 'weekId',
			width: '8%',
			render: (text: string, record: any) => {
				return <b>{record.weekId}</b>
			}
		},
		{
			key: 'clubId',
			title: <Popover content={'Tegenstander'} trigger="click">
				<span style={popoverStyle} >
					{'TG'}
				</span>
			</Popover>,
			dataIndex: 'clubId',
			width: '10%',
			render: (text: string, record: any) => {

				const weekMatches: any[] = this.props.matches.data
					.filter((match: any) => match.weekId === record.weekId && ([match.homeId.id, match.awayId.id].includes(record.clubId)));
				let opponent = '';

				if (weekMatches.length) {
					const opponentClubId = weekMatches[0].homeId.id === record.clubId ? weekMatches[0].awayId.id : weekMatches[0].homeId.id;
					const club = this.props.clubs.data.find((item: any) => item.id === opponentClubId);
					opponent = club ? club.short : '';
				}

				return <b>{opponent}</b>
			}
		},
		{
			key: 'time',
			width: '8%',
			title: <Popover content={'Tijd'} trigger="click">
				<span style={popoverStyle} >
					{'T'}
				</span>
			</Popover>,
			dataIndex: 'time',
			render: (text: string, record: any) => {
				const match = this.state.selectedPlayerStats.find((item: any) => item.match.id === record.matchId);
				let time = '';
				if (match && match.stat) {
					let parsedValue: any = {};
					try {
						parsedValue = JSON.parse(match.stat.value);
						time = parsedValue && parsedValue.time;
					} catch {
						time = 'N/A';
					}
				}
				return <b>{time}</b>
			}
		},
		{
			key: 'statGoals',
			width: '8%',
			title: <Popover content={'Doelpunten'} trigger="click">
				<span style={popoverStyle} >{'D'}</span></Popover>,
			dataIndex: 'statGoals',
			render: (text: string, record: any) => {
				return <b>{record.statGoals}</b>
			}
		},
		{
			key: 'statAssists',
			width: '8%',
			title: <Popover content={'Assists'} trigger="click"><span style={popoverStyle} >{'A'}</span></Popover>,
			dataIndex: 'statAssists',
			render: (text: string, record: any) => {
				return <b>{record.statAssists}</b>
			}
		},
		{
			key: 'statOwnGoal',
			width: '8%',
			title: <Popover content={'Eigen doelpunt'} trigger="click">
				<span style={popoverStyle} >{'ED'}</span></Popover>,
			dataIndex: 'statOwnGoal',
			render: (text: string, record: any) => {
				return <b>{record.statOwnGoal}</b>
			}
		},
		{
			key: 'statPenaltyMiss',
			width: '8%',
			title: <Popover content={'Penalty gemist'} trigger="click"><span style={popoverStyle} >{'PM'}</span></Popover>,
			dataIndex: 'statPenaltyMiss',
			render: (text: string, record: any) => {
				return <b>{record.statPenaltyMiss}</b>
			}
		},
		{
			key: 'statPenaltySave',
			width: '8%',
			title: <Popover content={'Penalty gestopt'} trigger="click"><span style={popoverStyle} >{'PS'}</span></Popover>,
			dataIndex: 'statPenaltySave',
			render: (text: string, record: any) => {
				return <b>{record.statPenaltySave}</b>
			}
		},
		{
			key: 'statYellow',
			width: '8%',
			title: <Popover content={'Gele kaart'} trigger="click"><span style={popoverStyle} ><YellowCardIcon /></span></Popover>,
			dataIndex: 'statYellow',
			render: (text: string, record: any) => {
				return <b>{record.statYellow}</b>
			}
		},
		{
			key: 'statRed',
			width: '8%',
			title: <Popover content={'Rode kaart'} trigger="click"><span style={popoverStyle} ><RedCardIcon /></span></Popover>,
			dataIndex: 'statRed',
			render: (text: string, record: any) => {
				return <b>{record.statRed}</b>
			}
		},
		{
			key: 'total',
			width: '8%',
			title: <Popover content={'Fantasypunten'} trigger="click"><span style={popoverStyle} >{'Punten'}</span></Popover>,
			dataIndex: 'total',
			render: (text: string, record: any) => {
				return <b>{record.total}</b>
			}
		}
		];

		const careerColumns = [
			{
				key: 'season',
				title: <Popover content={'Seizoen'} trigger="click">
					<span style={popoverStyle} >
						{'Seizoen'}
					</span>
				</Popover>,
				dataIndex: 'season',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record.season}</b>
				}
			},
			{
				key: 'team',
				title: <Popover content={'Club'} trigger="click">
					<span style={popoverStyle} >
						{'Club'}
					</span>
				</Popover>,
				dataIndex: 'team',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record.team}</b>
				}
			},
			{
				key: 'appearances',
				title: <Popover content={'W'} trigger="click">
					<span style={popoverStyle} >
						{'W'}
					</span>
				</Popover>,
				dataIndex: 'appearances',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record.appearances}</b>
				}
			},
			{
				key: 'goals',
				title: <Popover content={'D'} trigger="click">
					<span style={popoverStyle} >
						{'D'}
					</span>
				</Popover>,
				dataIndex: 'goals',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record.goals}</b>
				}
			},
			{
				key: 'game-minutes',
				title: <Popover content={'Min'} trigger="click">
					<span style={popoverStyle} >
						{'Min'}
					</span>
				</Popover>,
				dataIndex: 'game-minutes',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record['game-minutes']}</b>
				}
			},
			{
				key: 'goal-minutes',
				title: <Popover content={'G/90m'} trigger="click">
					<span style={popoverStyle} >
						{'G/90m'}
					</span>
				</Popover>,
				dataIndex: 'goal-minutes',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{ Math.round((90*record['goals'])/record['game-minutes'] * 100) / 100 || 0}</b>
				}
			},
			{
				key: 'lineups',
				title: <Popover content={'S'} trigger="click">
					<span style={popoverStyle} >
						{'S'}
					</span>
				</Popover>,
				dataIndex: 'lineups',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record.lineups}</b>
				}
			},
			{
				key: 'yellow-cards',
				title: <Popover content={'G'} trigger="click">
					<span style={popoverStyle} >
						{'G'}
					</span>
				</Popover>,
				dataIndex: 'yellow-cards',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record['yellow-cards']}</b>
				}
			},
			{
				key: 'red-cards',
				title: <Popover content={'R'} trigger="click">
					<span style={popoverStyle} >
						{'R'}
					</span>
				</Popover>,
				dataIndex: 'red-cards',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{+record['red-cards'] + +record['2nd-yellow-cards'] || 0}</b>
				}
			},
			{
				key: 'subs-in',
				title: <Popover content={'In'} trigger="click">
					<span style={popoverStyle} >
						{'In'}
					</span>
				</Popover>,
				dataIndex: 'subs-in',
				width: '10%',
				render: (text: string, record: any) => {
					return <b>{record['subs-in']}</b>
				}
			}
		]
		return (
			<div style={{marginTop: '20px'}}>
				{(navigateToMatch) && <Redirect
					to={{ pathname: `/match/${navigateToMatch}` }}
				/>}
				<Row>
					<Col md={24} sm={24} xs={24}>
						<Block>
							<Row style={{ padding: '5px' }}>
								<Title type={TitleType.h2}>{'Spelersstats'}</Title>
								<PlayerStatsList
									clubs={this.props.clubs.data}
									isLoading={this.props.players.isFetching}
									hidePositions={false}
									application={this.props.application}
									assetsCdn={this.props.application.competition.assetsCdn}
									playerType={PlayerType.SoccerPortrait}
									matches={matches}
									onSelect={this.setPlayer}
									action
									t={t}
									showHeader={true}
									size={10}
									user={this.props.user}
								/>
							</Row>
							<a
								href={`https://goudenspelen.s3.eu-west-1.amazonaws.com/files/spelers_${this.props.application.competition.competitionFeed}_${this.props.application.competition.seasonId}.xlsx`}>
								<Tooltip title="Beschikbaar na speeldag 6">
										<Button
											style={{width: '250px'}}
											size="large">
											<Icon type="file-excel" theme="filled"/>
											Spelerslijst (Excel)
										</Button>
								</Tooltip>
							</a>
						</Block>
					</Col>

					<Col md={24} sm={24} xs={24}>
						{
							(selectedPlayer &&
								<Block>
									<Element name="player-stats">
										<Title type={TitleType.h2}>{selectedPlayer.name}</Title>

										<Row>
											<Tabs defaultActiveKey="1" style={{ color: '#FFF' }}>
												<TabPane tab={'Carriere'} key="1" style={{ color: '#FFF' }}>
													<BasicTableStyle
														columns={careerColumns}
														dataSource={selectedPlayer.generalInfo.career}
														showHeader={true}
														locale={{ emptyText: 'Nog geen statistieken beschikbaar.' }}
														loading={false}
														pagination={false}
														rowKey={(record: any, index: number) => `record-${index + 1}`}
														rowClassName={(record: object, index: number) => index % 2 ? 'cursor-pointer ant-table-row--odd' : 'cursor-pointer ant-table-row--even'}
													/>
												</TabPane>
												<TabPane tab={'Dit seizoen'} key="2">
													<BasicTableStyle
														columns={columns}
														dataSource={selectedPlayerAggregatedStats}
														showHeader={true}
														locale={{ emptyText: 'Nog geen statistieken beschikbaar.' }}
														loading={false}
														pagination={false}
														onRow={(record: any) => ({
															onClick: (event: any) => {
																this.setState({ navigateToMatch: record.matchId });
															},
														})}
														rowKey={(record: any, index: number) => `record-${index + 1}`}
														rowClassName={(record: object, index: number) => index % 2 ? 'cursor-pointer ant-table-row--odd' : 'cursor-pointer ant-table-row--even'}
													/>												
												</TabPane>
											</Tabs>
										</Row>
									</Element>
								</Block>) || null
						}
					</Col>
				</Row>
			</div>
		);
	}
}

export function mapDispatchToProps(dispatch: Dispatch<userActions.UserAction>) {
	return {}
}

export function mapStateToProps({ application, players, clubs, matches, user }: StoreState.All) {
	return {
		application,
		players,
		matches,
		clubs,
		user
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats);






























// import React, {Component} from "react";
// import {connect} from 'react-redux';
// import {Dispatch} from 'redux';

// import * as userActions from '../../actions/user'
// import * as playersActions from '../../actions/players'
// import {StoreState, ApplicationState, PlayersState, ClubsState, Player} from './../../types';
// import PlayerPortrait from '../../components/PlayerPortrait/PlayerPortrait';
// import {ContainerStyle, SelectGroupStyle, BasicTableStyle} from './../../components/PlayerList/PlayerListStyle';
// import Link from './../../components/UI/Link/Link';
// import {Icon} from 'antd';

// import {Element, scroller} from 'react-scroll'

// import {
// 	Layout,
// 	Title,
// 	TitleType,
// 	PlayerList,
// 	PlayerType,
// 	Block,
// 	Button,
// 	Row,
// 	Col
// } from './../../components';

// export interface Props {
// 	application: ApplicationState;
// 	players: PlayersState;
// 	clubs: ClubsState;
// }

// export interface State {
// 	selectedPlayer: any,
// 	selectedPlayerStats: any
// }


// class Stats extends Component<Props, State> {

// 	constructor(props: Props) {
// 		super(props);

// 		this.state = {
// 			selectedPlayer: props.players.data[0] || null,
// 			selectedPlayerStats: []
// 		};
// 	};

// 	componentDidMount() {
// 		if(this.props.players.data.length) {
// 			this.getPlayerDetails(this.props.players.data[0]);
// 		}
// 	}

// 	getPlayerDetails(firstPlayer: Player) {
// 		playersActions.getPlayerDetails(
// 			this.props.application.competition.competitionFeed,
// 			this.props.application.competition.seasonId,
// 			firstPlayer.id
// 		)
// 			.then((result: any) => {
// 				this.setState({selectedPlayer: firstPlayer, selectedPlayerStats: result.stats || []});
// 			});
// 	}

// 	componentDidUpdate(prevProps: Props) {
// 		if ((this.props.players.data.length !== prevProps.players.data.length) && this.props.players.data.length) {
// 			this.getPlayerDetails(this.props.players.data[0]);
// 		}
// 	}

// 	setPlayer = (player: Player) => {
// 		playersActions.getPlayerDetails(
// 			this.props.application.competition.competitionFeed,
// 			this.props.application.competition.seasonId,
// 			player.id
// 		)
// 			.then((result: any) => {
// 				this.setState({selectedPlayer: player, selectedPlayerStats: result.stats || []});

// 				scroller.scrollTo('player-stats', {
// 					duration: 1000,
// 					delay: 100,
// 					smooth: true,
// 					offset: 0
// 				})
// 			});
// 	};

// 	render() {
// 		let selectedPlayerClub = null;

// 		if (this.state.selectedPlayer && this.props.clubs.data) {
// 			selectedPlayerClub = this.props.clubs.data.find((club: any) => club.id === this.state.selectedPlayer.clubId);
// 		}

// 		const columns = [{
// 			key: 'weekId',
// 			title: 'Speeldag',
// 			dataIndex: 'weekId',
// 			width: '50%',
// 			render: (text: string, record: any) => {
// 				return <b>{record.stat.weekId}</b>
// 			}
// 		}, /*{
// 			key: 'rank',
// 			width: '30%',
// 			title: 'Plaats',
// 			dataIndex: 'rank',
// 			render: (text: string, record: any) => {
// 				return <b>{record.stat.value}</b>
// 			}
// 		}, */{
// 			key: 'Punten',
// 			width: '50%',
// 			title: 'Punten',
// 			dataIndex: 'points',
// 			render: (text: string, record: any) => {
// 				return <b>{record.stat.points}</b>
// 			}
// 		}
// 		];
// 		const selectedPlayerIcon = `${this.props.application.competition.assetsCdn}/jerseys/football/${selectedPlayerClub && selectedPlayerClub.id}.png`;
// 		const selectedPlayerFallback = `${this.props.application.competition.assetsCdn}/jerseys/dummy.png`;
// 		return (
// 			<Layout>
// 				<Row>
// 					<Col md={12} sm={12} xs={24}>
// 						<Block>
// 							<Row>
// 								<Title type={TitleType.h2}>Alle spelers</Title>
// 								<PlayerList
// 									clubs={this.props.clubs.data}
// 									isLoading={this.props.players.isFetching}
// 									hidePositions={false}
// 									assetsCdn={this.props.application.competition.assetsCdn}
// 									playerType={PlayerType.SoccerShirt}
// 									data={this.props.players.data}
// 									onSelect={this.setPlayer}
// 									action
// 									showHeader={false}
// 									size={10}
// 								/>
// 							</Row>
// 						</Block>
// 						<Block>
// 							<Row justify="center">
// 								<Col md={12} sm={12} xs={24}>
// 									<Block>
// 										<a
// 											href={`https://goudenspelen.s3.eu-west-1.amazonaws.com/files/spelers_${this.props.application.competition.competitionFeed}.xlsx`}>
// 											<Button
// 												style={{width: '100%', maxWidth: '630px'}}
// 												size="large">
// 												<Icon type="file-excel" theme="filled"/>
// 												Spelerslijst (Excel)
// 											</Button>
// 										</a>
// 									</Block>
// 								</Col>
// 							</Row>
// 						</Block>
// 					</Col>

// 					<Col md={12} sm={12} xs={24}>
// 						{
// 							(this.state.selectedPlayer &&
// 								<Block>
// 									<Element name="player-stats">
// 										<Row>
// 											<Title type={TitleType.h2}>Stats</Title>
// 											<Col md={24} sm={25} xs={24}>
// 												<Row>
// 													<PlayerPortrait
// 														icon={selectedPlayerIcon}
// 														type={PlayerType.SoccerShirt}
// 														style={{height: '125px', width: '125px', margin: '0 auto'}}
// 														size={'120px'}
// 													/>
// 												</Row>

// 												<Row>
// 													<div style={{textAlign: 'center'}}>
// 														<Title type={TitleType.h4}>{this.state.selectedPlayer.name}</Title>
// 														<p>{selectedPlayerClub && selectedPlayerClub.name}</p>
// 													</div>
// 												</Row>

// 												<Row>
// 													<BasicTableStyle
// 														columns={columns}
// 														dataSource={this.state.selectedPlayerStats}
// 														showHeader={true}
// 														locale={{emptyText: 'Nog geen statistieken beschikbaar.'}}
// 														loading={false}
// 														pagination={{pageSize: 10}}
// 														rowKey={(record: any, index: number) => `record-${index + 1}`}
// 														rowClassName={(record: object, index: number) => index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}
// 													/>
// 												</Row>
// 											</Col>
// 										</Row>
// 									</Element>
// 								</Block>) || null
// 						}
// 					</Col>
// 				</Row>
// 			</Layout>
// 		);
// 	}
// }

// export function mapDispatchToProps(dispatch: Dispatch<userActions.UserAction>) {
// 	return {}
// }

// export function mapStateToProps({application, players, clubs}: StoreState.All) {
// 	return {
// 		application,
// 		players,
// 		clubs
// 	}
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Stats);