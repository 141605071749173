import styled from "styled-components";
import { mediaQueries } from "../../styles/media-queries";
import 'antd/lib/table/style';

const ContainerStyle = styled.div`
	padding: 10px;
	.game-day {
		a.team {
			border-bottom: 0;
		}

		.team {
			width: 100%;

			.team-name {
				width: 60%;
				
				@media ${mediaQueries.tablet} {
					width: 70%;
				}
				
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}

	table {
		tr {
			cursor: pointer;
			
			td:first-child {
				text-align right;
			}

			td:nth-child(2) {
				text-align: center;
			}

			td:last-child {
				text-align left;
			}
		}

		td { 
			.team-name, .score {
			}

			.score {
				&.started {
				}
			}
		}
	}
`;

const ClubBadgeBg = styled.img`
	max-width: 35px;
	display: inline-block;
	margin: 5px;
` as any;

const ClubName : any = styled.span`
	&::after {
		content: "${(props: any) => props.shortName}"; 
	}	

	@media ${mediaQueries.tablet} {
		&::after {
			content: "${(props: any) => props.fullName}"; 
		}
	}	
`;


const FiltersArea = styled.div`
	text-align: center;
	margin: 20px;

	.ant-btn-primary {
		color: ${props => props.theme.primaryColor};
		background-color: #9E9E9E;
	}

	.ant-select {
		max-width: 150px;
	}
` as any;

export {
	ContainerStyle,
	ClubBadgeBg,
	FiltersArea,
	ClubName
};